import baobab from '../../data/state';
import { rest } from '../../data/rest/api';
import { startGlobalLoading, stopGlobalLoading } from '../../layout/globalLoading';
import { toast } from 'react-toastify';

function getModelTemplate() {
	return {
		uuid: '',
		company: 'Mandeks d.o.o.',
		package: 'Description + feature flags',
		featureFlags: [],
		activationDate: Date.now(),
		lastLicenceRefresh: Date.now(),
		expirationDate: Date.now() + 60 * 24 * 60 * 60 * 1000,
		deploymentVersion: null,
		trialToDate: Date.now() + 30 * 24 * 60 * 60 * 1000,
		licenceObject: {
			enableApiIntegration:false,
			hwSupport: false,
			usersCount: 10,
			devicesCount: 20,
			roomsCount: 50,
			usersByRoleCount: {
	            admin: 1,
	            hwAdmin: 0,
	            managerFull: 0,
	            managerBussiness: 0,
	            managerHousehold: 0,
	            managerSecurity: 0,
	            receptionist: 1,
	            maid: 0,
	            janitor: 0,
	            security: 0,
	            reporting: 1,
	            gdprOfficer: 0,
	            condoUser: 0,
	        },
		},
		licenceKey: null,
	};
}

async function saveLicence(licence) {
	try {
		if (licence.uuid.length !== 36) {
			throw new Error('Operation failed! Installation key is required!');
		}
		startGlobalLoading();
		const { uuid } = licence;
		let result = null;
		if (baobab.root.select('licences', { uuid }).get()) {
			baobab.root.select('licences', { uuid }).set(licence);
		} else {
			baobab.root.select('licences').push(licence);
		}
		result = await rest('POST', '/api/licence/' + uuid, licence);
		baobab.root.select('licences', { uuid }).set(result);
		stopGlobalLoading();
		return result;
	} catch (err) {
		stopGlobalLoading();
		toast.error(err.message);
	}
}

async function requestLicenceFromBase64Request(licenceRequest) {
	try {
		startGlobalLoading();
		const liceReq = await rest('POST', '/api/licence/request', { licenceRequest });
		const licences = await rest('GET', '/api/licence');
		baobab.root.select('licences').set(licences);
		stopGlobalLoading();
	} catch (err) {
		stopGlobalLoading();
		toast.error(err.message);
	}
}

async function generateLicence(uuid){
	return await rest('GET', 'api/licence/generate/'+uuid);
}



export { getModelTemplate, saveLicence, requestLicenceFromBase64Request, generateLicence };