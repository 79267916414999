import React, { useEffect, useState } from 'react'
import { rest } from '../../data/rest/api';

export default function Invoices(props) {
  const [invoicesList, setInvoices] = useState([])

  useEffect(() => {
    const loadData = async () => {

      const inv = await rest('POST', '/getInstanceInvoice', { instanceName: props.match.params.instanceName });

      setInvoices(inv);
    };

    loadData().catch((err) => {
      console.log(err);
    });
    // eslint-disable-next-line
  }, [])


  const createInvoice = async (month, year) => {
    const inv = await rest('POST', '/createInvoice', { instanceName: props.match.params.instanceName, month: month, year: year });
  }

  let showLastMonthWithoutInvoice = true


  // const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]


  return (
    <div>
      <div style={{ position: "relative", background: "#e7e7e7", flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row" }}
      >
        <span style={{ width: "50px" }}>#</span>
        <span style={{ flex: 1 }}>Month</span>
        <span style={{ flex: 1 }}>Year</span>
        <span style={{ flex: 1 }}></span>
        <span style={{ flex: 1 }}> </span>
        <span style={{ flex: 1 }}>Total value</span>

      </div>


      {/* {months.map((mon, ind) => {
        const currYear = new Date().getFullYear()
        const currMonth = new Date().getMonth()


        if (mon > currMonth - 1) { return null }


        console.log('billingHeaderItem', invoicesList);



        const billingHeaderItem = invoicesList.find(billingHeaderItm => (mon === billingHeaderItm.month && currYear === billingHeaderItm.year))
        if (billingHeaderItem) {
          return (
            <div key={ind}>
              <div
                style={{
                  position: "relative", background: ind % 2 === 1 ? "white" : "#e7e7e7",
                  flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row"
                }}
                onClick={() => { props.history.push(`/invoices/` + props.match.params.instanceName + "/" + billingHeaderItem.id) }}
              >
                <span style={{ width: "50px" }}>
                  {ind + 1}
                </span>
                <span style={{ flex: 1 }}>{billingHeaderItem.month + 1}</span>
                <span style={{ flex: 1 }}>{billingHeaderItem.year}</span>
                <span style={{ flex: 1 }}>{ }</span>
                <span style={{ flex: 1 }}>{

                }</span>
                <span style={{ flex: 1 }}>{
                  Number(billingHeaderItem.totalValue).toFixed(4)
                }</span>
              </div>
            </div>
          )
        } else {
          return (
            <div key={ind}>
              <div
                style={{
                  position: "relative", background: ind % 2 === 1 ? "white" : "#e7e7e7",
                  flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row"
                }}
              >
                <span style={{ width: "50px" }}>
                  {ind + 1}
                </span>
                <span style={{ flex: 1 }}>{mon + 1}</span>
                <span style={{ flex: 1 }}>{currYear}</span>
                <span style={{ flex: 1 }}>{ }</span>
                <span style={{ flex: 1 }}>{

                }</span>
                <span style={{ flex: 1 }}>
                  <div className='btn btn-info' onClick={() => { createInvoice(mon, currYear) }}>Create invoice</div></span>
              </div>
            </div>
          )
        }

      })} */}





{invoicesList.map((billingHeaderItem, ind) => {


const now = new Date()

if (Number(billingHeaderItem.month) === now.getMonth() - 1 && Number(billingHeaderItem.year) === now.getFullYear()) {
  showLastMonthWithoutInvoice = false
}


return (
  <div key={ind}>
    {/* <div onClick={async (e) => { e.stopPropagation(); await getBillingInvoiceItems(billingHeaderItem.id) }}  */}
    <div
      style={{
        position: "relative", background: ind % 2 === 1 ? "white" : "#e7e7e7",
        flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row"
      }}
      onClick={() => { props.history.push(`/invoices/` + props.match.params.instanceName + "/" + billingHeaderItem.id) }}
    >
      <span style={{ width: "50px" }}>
        {ind + 1}
      </span>
      <span style={{ flex: 1 }}>{billingHeaderItem.month +1}</span>
      <span style={{ flex: 1 }}>{billingHeaderItem.year}</span>
      <span style={{ flex: 1 }}>{}</span>
      <span style={{ flex: 1 }}>{
        
      }</span>
      <span style={{ flex: 1 }}>{
        Number(billingHeaderItem.totalValue).toFixed(4)
      }</span>
    </div>
  </div>

)
})}
{showLastMonthWithoutInvoice ?
<div>
  {/* <div onClick={async (e) => { e.stopPropagation(); await getBillingInvoiceItems(billingHeaderItem.id) }} */}
  <div
    style={{
      position: "relative", background: "#e7e7e7",
      flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row"
    }}
  //  onClick={()=>{props.history.push(`/invoices/`+ props.match.params.instanceName+"/" + billingHeaderItem.id)}}
  >

    <div className='btn btn-info' onClick={() => { createInvoice() }}>Create invoice</div>

  </div>
</div> : null} 
    </div>

  )
}
