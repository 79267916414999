import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { branch } from 'baobab-react/higher-order';
import { getModelTemplate, saveLicence, requestLicenceFromBase64Request } from './api';

function LicenceFromBase64({ setBase64Request, base64Request }) {
	return (
		<div>
			<div className="form-group mt-2">
				<textarea
					rows={6}
					onChange={(e) => {
						setBase64Request(e.target.value);
					}}
					className="form-control"
					value={base64Request}
				></textarea>
			</div>
		</div>
	);
}

function Form({ licence, setLicence, isEdit = false }) {
	const roleNames = licence.licenceObject.usersByRoleCount ? Object.keys(licence.licenceObject.usersByRoleCount) : [];

	return (
		<div>
			<div className="form-group mt-2">
				<label>Installation Id</label>
				<input
					readOnly={isEdit}
					onChange={(e) => {
						setLicence({
							...licence,
							uuid: e.target.value,
						});
					}}
					type="text"
					value={licence.uuid}
					className="form-control"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Company</label>
				<input
					onChange={(e) => {
						setLicence({
							...licence,
							company: e.target.value,
						});
					}}
					type="text"
					value={licence.company}
					className="form-control"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Administrative email</label>
				<input
					onChange={(e) => {
						setLicence({
							...licence,
							administrativeEmail: e.target.value,
						});
					}}
					type="text"
					value={licence.administrativeEmail}
					className="form-control"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Administrative contact info</label>
				<input
					onChange={(e) => {
						setLicence({
							...licence,
							administrativeContactInfo: e.target.value,
						});
					}}
					type="text"
					value={licence.administrativeContactInfo}
					className="form-control"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Feature flags</label>
				<textarea
					readOnly={true}
					onChange={(e) => {
						setLicence({ ...licence, featureFlags: e.target.value.split(',') });
					}}
					value={licence.featureFlags}
					className="form-control"
					placeholder="Feature flags "
				/>
			</div>

			<div className="form-group mt-2">
			<label>Users count</label>
				<ul className="list-group">
					{roleNames.map((roleName) => {
						return (
							<li className="list-group-item" key={roleName}>
								<span style={{ width: '200px', display: 'inline-block' }}> {roleName}</span>
								<input
									onChange={(e) => {
										const tmp = JSON.parse(JSON.stringify(licence.licenceObject)); //deep clone
										tmp.usersByRoleCount[roleName] = e.target.value ? Number(e.target.value) : 0;
										setLicence({
											...licence,
											licenceObject: { ...tmp },
										});
									}}
									value={licence.licenceObject.usersByRoleCount[roleName]}
									type="number"
								/>
							</li>
						);
					})}
				</ul>
			</div>

			<div className="form-group mt-2">
				<label>Rooms count</label>
				<input
					onChange={(e) => {
						setLicence({
							...licence,
							licenceObject: { ...licence.licenceObject, roomsCount: e.target.value },
						});
					}}
					type="number"
					value={licence.licenceObject.roomsCount}
					className="form-control"
				/>
			</div>

			<div className="form-group mt-2">
				<label htmlFor="hwSupport">Hardware support</label>
				&ensp;
				<input
					id="hwSupport"
					onChange={(e) => {
						setLicence({
							...licence,
							licenceObject: { ...licence.licenceObject, hwSupport: e.target.checked },
						});
					}}
					type="checkbox"
					checked={licence.licenceObject.hwSupport}
				/>
			</div>

			<div className="form-group mt-2">
				<label htmlFor="enableApiIntegration">API integration</label>
				&ensp;
				<input
					id="enableApiIntegration"
					onChange={(e) => {
						setLicence({
							...licence,
							licenceObject: { ...licence.licenceObject, enableApiIntegration: e.target.checked },
						});
					}}
					type="checkbox"
					checked={licence.licenceObject.enableApiIntegration}
				/>
			</div>

			<div className="form-group mt-2">
				<label>Devices count</label>
				<input
					onChange={(e) => {
						setLicence({
							...licence,
							licenceObject: { ...licence.licenceObject, devicesCount: e.target.value },
						});
					}}
					type="number"
					value={licence.licenceObject.devicesCount}
					className="form-control"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Trial to</label>
				<DateTimePicker
					className="form-control"
					required={true}
					onChange={(selectedDate) => {
						if (selectedDate) {
							setLicence({ ...licence, trialToDate: selectedDate.valueOf() });
						} else {
							setLicence({ ...licence, expirationDate: Date.now() });
						}
					}}
					value={new Date(licence.trialToDate)}
				/>
			</div>

			<div className="form-group mt-2">
				<label>Expiration date</label>
				<DateTimePicker
					className="form-control"
					required={true}
					onChange={(selectedDate) => {
						if (selectedDate) {
							setLicence({ ...licence, expirationDate: selectedDate.valueOf() });
						} else {
							setLicence({ ...licence, expirationDate: Date.now() });
						}
					}}
					value={new Date(licence.expirationDate)}
				/>
			</div>

			<div className="form-group mt-2">
				<label>Notes</label>
				<textarea
					rows={6}
					onChange={(e) => {
						setLicence({
							...licence,
							notes: e.target.value,
						});
					}}
					className="form-control"
					value={licence.notes ? licence.notes : null}
				></textarea>
			</div>

			<hr />
		</div>
	);
}

function LicenceForm(props) {
	const [licence, setLicence] = React.useState(null);
	const [base64Request, setBase64Request] = React.useState('');
	const [pasteRequest, setPasteRequest] = React.useState(false);
	const { uuid = null } = props.match?.params ? props.match.params : null;

	const isEditForm = props.match.url.lastIndexOf('/new') === -1;

	React.useEffect(() => {
		if (uuid === null) {
			const newInstance = getModelTemplate();
			setLicence(newInstance);
		} else {
			const target = props.licences.find((i) => i.uuid === uuid);
			if (target) {
				setLicence(target);
			}
		}
	}, [uuid, props.licences]);

	if (licence === null) {
		return 'Loading ...';
	}

	return (
		<div className="container">
			<div className="jumbotron jumbotron-fluid">
				<div className="">
					<h1 className="display-4">Licence form</h1>
					<p className="lead">
						Adjust licence properties. Licence information would be propagated to the installation machines,
						prior reneval period or you have to update it manualy.
					</p>
				</div>

				<button
					onClick={() => {
						setPasteRequest(!pasteRequest);
					}}
					className="btn btn-outline-danger"
				>
					{pasteRequest ? 'GENERATE IN FORM' : 'PASTE REQUEST'}
				</button>
			</div>
			{pasteRequest ? (
				<LicenceFromBase64 base64Request={base64Request} setBase64Request={setBase64Request} />
			) : (
				<Form licence={licence} setLicence={setLicence} isEdit={isEditForm} />
			)}
			<button
				onClick={() => {
					if (pasteRequest) {
						requestLicenceFromBase64Request(base64Request);
						props.history.push('/licences');
					} else {
						saveLicence(licence);
						props.history.push('/licences');
					}
				}}
				className="btn btn-primary mt-3 mb-5"
			>
				SAVE LICENCE
			</button>
			&ensp;
			<button
				onClick={() => {
					props.history.goBack();
				}}
				className="btn btn-outline-primary mt-3 mb-5"
			>
				CANCEL
			</button>
		</div>
	);
}

export default branch(
	{
		licences: ['licences'],
	},
	LicenceForm
);