import React from 'react';
import Header from './header';
import { withRouter } from 'react-router-dom';
import { GlobalLoading } from './globalLoading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainModal from './modal';

const MasterLayoutWithRouter = (props) => {
	return (
		<div style={{ height: '100vh', overflow: 'auto' }} className=" bg-light">
			<Header {...props} />
			<ToastContainer />
			<GlobalLoading />
			<MainModal />

			<div className="container-fluid mt-2">{props.children}</div>
		</div>
	);
};

export default withRouter(MasterLayoutWithRouter);