import React from 'react';
import { FiDollarSign, FiList } from 'react-icons/fi';
import { useBranch } from 'baobab-react/hooks';
// import { deleteInstance } from './api';
// import { closeModal, showModal } from '../../layout/modal';

// const DeleteModal = ({ deleteCandidate }) => {
// 	return (
// 		<div>
// 			<h3>Are you sure you want to delete {deleteCandidate.name}?</h3>
// 			<p>
// 				<b>This operation is not reversibile!</b> After confirmation, all related data and databases would also
// 				be permanentyl removed.
// 			</p>

// 			<button
// 				onClick={() => {
// 					closeModal();
// 				}}
// 				className="btn btn-secondary"
// 			>
// 				CANCEL
// 			</button>
// 			<button
// 				style={{ marginLeft: '10px' }}
// 				onClick={() => {
// 					deleteInstance(deleteCandidate);
// 					closeModal();
// 				}}
// 				className="btn btn-danger"
// 			>
// 				CONFIRM
// 			</button>
// 		</div>
// 	);
// };

const Billing = (props) => {
	const { instances } = useBranch({
		instances: ['instances'],
	});

	return (
		<div className="container">
			<div onClick={()=>{props.history.push("/billingFeatresTable")}} className='btn btn-primary'>Show table</div>
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					{instances.map((t) => {
						return (
							<tr key={t.uuid}>
								<th scope="row">{t.name}</th>
								<td style={{textAlign:"right"}}>
									<button
										onClick={() => {
											props.history.push(`/billingFeatureItems/`+ t.name);
										}}
										className="btn btn-outline-warning"
									>
										<FiList />{' '}
									</button>
									&ensp;
									<button
										onClick={() => {
											props.history.push(`/invoices/`+ t.name);
										}}
										className="btn btn-outline-primary"
									>
										<FiDollarSign />{' '}
									</button>
									&ensp;
									{/* <button
										onClick={() => {
											showModal(
												'Please take a precaution ',
												<DeleteModal deleteCandidate={t} />,
												false
											);
										}}
										className={'btn btn-danger'}
									>
										<FiTrash2 />
									</button> */}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Billing;