import React, { useEffect, useState } from 'react'

export default function BillingFeatureForm(props) {
	const [billingFeatureItem, setBillingFeatureItem] = useState({...{newPriceValidFromNextMonth:false, applyDiscountPercentage:false }})

	const onFieldChange = (something) => {

		let billinFItm = { ...billingFeatureItem }
		let value = something.target.value

		if (something.target.value === "false") {
			value = false
		}
		if (something.target.value === "true") {
			value = true
		}
		if (something.target.name === "featureFlags") {
			let fflagArr = something.target.value.split(",")
			billinFItm[something.target.name] = fflagArr
			setBillingFeatureItem(billinFItm)
		}else if(something.target.name === "discountPercentage"){
			if(value > 100){
				value = 100
			}
			billinFItm[something.target.name] = value
			setBillingFeatureItem(billinFItm)
		}else{
			billinFItm[something.target.name] = value

			setBillingFeatureItem(billinFItm)
		}
		
	}

	useEffect(() => {
		const billingFeatureItemFromPros = props.billingFeatureItem

		
		setBillingFeatureItem({...billingFeatureItemFromPros, newPriceValidFromNextMonth:false, applyDiscountPercentage:billingFeatureItemFromPros.discountPercentage ? true : false })
		// eslint-disable-next-line
	}, [props.billingFeatureItem.name])

	const onMonthChange = (e) =>{
		const [year, month] = e.target.value.split("-")

		const date = new Date()
		date.setMonth(Number(month) - 1)
		date.setFullYear(Number(year))
		// date.setHours(Number(12))
		date.setUTCHours(0,0,0,0)

		let billinFItm = { ...billingFeatureItem }
		var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		let value = firstDay.getTime()

		billinFItm["newMonthlyUsagePriceValidFrom"] = value
		setBillingFeatureItem(billinFItm)		
	}

const dateForPicker = billingFeatureItem.newMonthlyUsagePriceValidFrom ? new Date(billingFeatureItem.newMonthlyUsagePriceValidFrom) : new Date()
const monthValue = `${dateForPicker.getFullYear()}-${(dateForPicker.getMonth()+1).toString().length === 1 ? `0${dateForPicker.getMonth()+1}` : dateForPicker.getMonth()+1 }`

	return (
		<div>
			<div className="form-group mt-2">
				<label>Name</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, version: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.name ? billingFeatureItem.name : ""}
					type="text"
					className="form-control"
					placeholder="Version (Docker tag)"
					name="name"
					disabled
				/>
			</div>

			<div className="form-group mt-2">
				<label>Description</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, adminEmail: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.description ? billingFeatureItem.description : ""}
					type="text"
					className="form-control"
					placeholder="Enter description"
					name="description"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Grace period (in hours)</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, adminName: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.gracePeriodInHours ? billingFeatureItem.gracePeriodInHours : ""}
					type="text"
					className="form-control"
					placeholder="Grace period in hours"
					name="gracePeriodInHours"
				/>
			</div>
			<div className="form-group mt-2">
				<label>Monthly usage price</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, company: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.monthlyUsagePrice ? billingFeatureItem.monthlyUsagePrice : ""}
					type="text"
					className="form-control"
					placeholder="Monthly usage price"
					name="monthlyUsagePrice"
				/>
			</div>
			

			<div className="form-group mt-2">
				<label>New monthly usage price</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, company: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.newMonthlyUsagePrice ? billingFeatureItem.newMonthlyUsagePrice : ""}
					type="text"
					className="form-control"
					placeholder="New monthly usage price"
					name="newMonthlyUsagePrice"
				/>
			</div>

			<div className="form-group mt-2">
				<label htmlFor='newMonthlyUsagePriceValidFrom'>New Price Valid From Month</label>
				{/* <input
					onChange={(e) => {
						// setInstance({ ...instance, company: e.target.value });
						onFieldChange(e)
					}}
					id="newMonthlyUsagePriceValidFrom"
					value={billingFeatureItem.newMonthlyUsagePriceValidFrom}
					type="checkbox"
					// disabled={billingFeatureItem.newMonthlyUsagePriceValidFrom ? false : true}
					// className="form-control"
					placeholder="New monthly usage price"
					name="newMonthlyUsagePriceValidFrom"
				/> */}

				<input name="newMonthlyUsagePriceValidFrom" type="month" id="start" className='form-control' onChange={onMonthChange}
					min={`${new Date().getFullYear()}-${(new Date().getMonth()+1).toString().length === 1 ? `0${new Date().getMonth()+1}` : new Date().getMonth()+1 }`} value={monthValue} />
				
			</div>
			

			<div className="form-group mt-2">
				<label>Minimum monthly discounted usage price</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.minimumDiscountedMonthlyUsagePrice ? billingFeatureItem.minimumDiscountedMonthlyUsagePrice : ""}
					className="form-control"
					placeholder="Minimum monthly discounted usage price"
					name="minimumDiscountedMonthlyUsagePrice"
				/>
			</div>

			<div className="form-group mt-2">
				<label>applyDiscountPercentage</label>

				<select className="form-control"
					name="applyDiscountPercentage" value={billingFeatureItem.applyDiscountPercentage ? billingFeatureItem.applyDiscountPercentage : false} onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}} >
					<option value={false}>No</option>
					<option value={true}>Yes</option>
				</select>
			</div>

			
				{billingFeatureItem.applyDiscountPercentage ? <div className="form-group mt-2">
				<label>Discount percentage</label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.discountPercentage ? billingFeatureItem.discountPercentage : ""}
					className="form-control"
					placeholder="Discount percentage"
					name="discountPercentage"
					type='number'
				/>
			</div> : null}
			


			<div className="form-group mt-2">
				<label>Unit(s)  <span className='text-danger'>(if there is more than one unit separate them with ; for example room;channel)</span></label>
				<input
					onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.unit}
					className="form-control"
					placeholder="Unit"
					name="unit"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Enabled</label>

				<select className="form-control"
					name="enabled" value={billingFeatureItem.enabled ? billingFeatureItem.enabled : false} onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}} >
					<option value={false}>No</option>
					<option value={true}>Yes</option>
				</select>
			</div>


			<div className="form-group mt-2">
				<label>Enable occupancy based discount</label>

				<select className="form-control"
					name="enableOccupancyDiscount" value={billingFeatureItem.enableOccupancyDiscount ? billingFeatureItem.enableOccupancyDiscount : false} onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}} >
					<option value={false}>No</option>
					<option value={true}>Yes</option>
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Lock feature to prevent user from activating and deactivating</label>

				<select className="form-control"
					name="locked" value={billingFeatureItem.locked ? billingFeatureItem.locked : false} onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}} >
					<option value={false}>No</option>
					<option value={true}>Yes</option>
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Feature flags</label>
				<textarea
					rows="10"
					onChange={(e) => {
						// setInstance({ ...instance, adminPassword: e.target.value });
						onFieldChange(e)
					}}
					value={billingFeatureItem.featureFlags ? billingFeatureItem.featureFlags : ""}
					className="form-control"
					placeholder="Feature flags"
					name="featureFlags"
				/>
			</div>
			<button className='btn btn-primary' onClick={() => { props.saveItem(billingFeatureItem); props.closeModal() }}>Save</button>
			<button className='btn btn-info' onClick={() => { props.closeModal() }}>Cancel</button>
		</div>
	)
}
