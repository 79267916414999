import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css"
import baobabTree from "./data/state";
import Routes from "./router";
import { root } from "baobab-react/higher-order";

const RootedApp = root(baobabTree, Routes);

ReactDOM.render(
  <React.StrictMode>
    <RootedApp />
  </React.StrictMode>,
  document.getElementById("root"),
  async () => {}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// komentar
reportWebVitals();