import React from 'react';
import { getModelTemplate, createInstance, updateInstance } from './api';
import { branch } from 'baobab-react/higher-order';

const statuses = ['turned_off', 'running', 'sleep'];
const reqMemOptions = ['0Mi', '128Mi', '256Mi', '512Mi', '1024Mi', '1280Mi', '1536Mi', '2048Mi', '4096Mi'];
const reqCPUOptions = ['0m', '10m', '100m', '200m', '500m', '750m', '1000m', '2000m'];
const priorityOptions = ['low-priority', 'medium-priority', 'high-priority'];

const Form = ({ instance, setInstance }) => {
	if (instance === null) {
		return 'Loading ...';
	}
	// endContractTimstamp
	// startContractTimstamp
	const handleDateChange = (event) => {
		const selectedDate = new Date(event.target.value);
		const selectedTimestamp = selectedDate.getTime();
		setInstance({ ...instance, [event.target.name]: selectedTimestamp });
	  };
	
	  const formatTimestampToDate = (timestamp) => {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		let month = date.getMonth() + 1;
		month = month < 10 ? `0${month}` : month;
		let day = date.getDate();
		day = day < 10 ? `0${day}` : day;
		return `${year}-${month}-${day}`;
	  };

	return (
		<div className="w-50 ">
			<div className="form-group mt-2">
				<label>Instance name</label>
				<input
					disabled={instance.uuid!==null}
					onChange={(e) => {
						if(e.target.value.includes(' ')){
							setInstance({ ...instance, name: '' });
						} else {
							setInstance({ ...instance, name: e.target.value.toLowerCase() });
						}

					}}
					value={instance.name}
					type="text"
					className="form-control"
					placeholder="Instance name"
				/>
				<small className="form-text">No whitespaces please</small>
			</div>

			<div className="form-group mt-2">
				<label>Version</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, version: e.target.value });
					}}
					value={instance.version}
					type="text"
					className="form-control"
					placeholder="Version (Docker tag)"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Administrator email</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, adminEmail: e.target.value });
					}}
					value={instance.adminEmail}
					type="email"
					className="form-control"
					placeholder="Enter administrator email"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Administrator name</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, adminName: e.target.value });
					}}
					value={instance.adminName}
					type="text"
					className="form-control"
					placeholder="Enter administrator name"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Administrator password</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, adminPassword: e.target.value });
					}}
					value={instance.adminPassword}
					type="password"
					className="form-control"
					placeholder="Administrator password"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Company</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, company: e.target.value });
					}}
					value={instance.company}
					type="text"
					className="form-control"
					placeholder="Enter client company"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Status</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, status: e.target.value });
					}}
					value={instance.status}
					className="form-control"
				>
					{statuses.map((s, index) => {
						return (
							<option key={index} value={s}>
								{s}
							</option>
						);
					})}
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Requests memory</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, requestsMemory: e.target.value });
					}}
					value={instance.requestsMemory}
					className="form-control"
				>
					{reqMemOptions.map((s, index) => {
						return (
							<option key={index} value={s}>
								{s}
							</option>
						);
					})}
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Requests CPU</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, requestsCPU: e.target.value });
					}}
					value={instance.requestsCPU}
					className="form-control"
				>
					{reqCPUOptions.map((s, index) => {
						return (
							<option key={index} value={s}>
								{s}
							</option>
						);
					})}
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Limits memory</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, limitsMemory: e.target.value });
					}}
					value={instance.limitsMemory}
					className="form-control"
				>
					{reqMemOptions.map((s, index) => {
						return (
							<option key={index} value={s}>
								{s}
							</option>
						);
					})}
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Limits CPU</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, limitsCPU: e.target.value });
					}}
					value={instance.limitsCPU}
					className="form-control"
				>
					{reqCPUOptions.map((s, index) => {
						return (
							<option key={index} value={s}>
								{s}
							</option>
						);
					})}
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Priority</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, priority: e.target.value });
					}}
					value={instance.priority}
					className="form-control"
				>
					{priorityOptions.map((s, index) => {
						return (
							<option key={index} value={s}>
								{s}
							</option>
						);
					})}
				</select>
			</div>

			<div className="form-group mt-2">
				<label>Partner</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, partner: e.target.value });
					}}
					value={instance.partner}
					type="text"
					className="form-control"
					placeholder="Enter client partner"
				/>
			</div>

			<div className="form-group mt-2">
				<label>Partner</label>
				<input
					onChange={(e) => {
						setInstance({ ...instance, partner: e.target.value });
					}}
					value={instance.partner}
					type="text"
					className="form-control"
					placeholder="Enter client partner"
				/>
			</div>
			<div className="form-group mt-2">
				<label>Contract active</label>
				<select
					onChange={(e) => {
						setInstance({ ...instance, contractActive: e.target.value === "true" ? true : false });
					}}
					value={instance.contractActive ? instance.contractActive : false}
					className="form-control"
				>
					<option value={true}>
						YES
					</option>
					<option value={false}>
						NO
					</option>
				</select>
			</div>


			<div className="form-group mt-2">
				<label>Contract start date</label>
				<input name="startContractTimstamp" value={formatTimestampToDate(instance.startContractTimstamp? instance.startContractTimstamp : new Date().getTime())} className='form-control' type='date' onChange={handleDateChange}>
				</input>
			</div>


			<div className="form-group mt-2">
				<label>Contract end date</label>
					<input name="endContractTimstamp"  value={formatTimestampToDate(instance.endContractTimstamp ? instance.endContractTimstamp : new Date().getTime())} className='form-control' type='date'  onChange={handleDateChange}> 
				</input>
			</div>




		</div>
	);
};

const InstanceForm = (props) => {
	const [instance, setInstance] = React.useState(null);
	const { uuid = null } = props.match?.params ? props.match.params : null;


	const formActionName = props.match.path.lastIndexOf("edit")===-1 ? "New" : "Edit"

	React.useEffect(() => {
		if (uuid === null) {
			const newInstance = getModelTemplate();
			setInstance(newInstance);
		} else {
			const target = props.instances.find(i=>i.uuid===uuid)
			if(target){
				setInstance(target);
			}
			
		}
	}, [uuid, props.instances]);

	const validate = () => {
		let invalid = [];
		if (instance.name.length < 2) {
			invalid.push({
				key: 'name',
				fieldDesc: 'Instance name',
				msg: 'Name have to be at least 3 characters long',
			});
		}

		if (instance.version.length < 5) {
			invalid.push({ key: 'version', fieldDesc: 'Version', msg: 'Version is probably not corectly selected.' });
		}

		return invalid;
	};

	const deploy = async () => {
		const invalid = validate();
		if (invalid.length === 0) {
			if (instance.uuid === null) {
				await createInstance(instance);
			} else {
				await updateInstance(instance);
			}
			props.history.push("/instances");
		} else {
			for (const i of invalid) {
				alert(`${i.fieldDesc} not valid. Message: ${i.msg}`);
			}
		}
	};

	return (
		<div className="container">
			<div className="jumbotron jumbotron-fluid">
				<div className="">
					<h1 className="display-4">{formActionName} instance</h1>
					<p className="lead">
						Create new subdomain <b>{instance?.name}</b>.hologic.io and deploy pods with properties
						defined in this form.
					</p>
				</div>
			</div>

			<Form instance={instance} setInstance={setInstance} />

			<button onClick={deploy} className="btn btn-primary mt-3 mb-5">
				DEPLOY
			</button>
			&ensp;
			<button onClick={()=>{
				props.history.goBack()
			}} className="btn btn-outline-primary mt-3 mb-5">
				CANCEL
			</button>
		</div>
	);
};

export default branch(
    {
        instances: ['instances'],
    },
    InstanceForm
);