import React from 'react';
import { generateLicence } from './api';
import { branch } from 'baobab-react/higher-order';

const LicenceView = (props) => {
	const { uuid = null } = props.match?.params ? props.match.params : null;

	const [licenceInfo, setLicenceInfo] = React.useState(null);


	let licence = props.licences.find(l=>l.uuid===uuid);
	if(!licence){
		licence = {}
	}

	React.useEffect(() => {
		generateLicence(uuid).then((info) => {
			setLicenceInfo(info);
		});
	}, [uuid]);

	if (licenceInfo === null) {
		return 'Loading ...';
	}

	return (
		<div className="container">
			<div className="jumbotron jumbotron-fluid">
				<div className="">
					<h1 className="display-4">Product licence</h1>
					<p className="lead">
						Licence and licence key for installation id <b>{uuid}</b>
					</p>
				</div>
			</div>

			<div className="form-group mt-2">
				<label>Licence key</label>
				<input readOnly={true} type={'text'} className="form-control" value={licenceInfo.licenceKey}></input>
			</div>

			<div className="form-group mt-2">
				<label>Licence</label>
				<textarea readOnly={true} rows={5} className="form-control" value={licenceInfo.licence}></textarea>
			</div>

			<div className="form-group mt-2">
				<label>Persistent licence location</label>
				<input
					readOnly={true}
					type="text"
					className="form-control"
					value={`https://${window.location.host}/api/licence/generate/${uuid}`}
				></input>
			</div>


			<div className="form-group mt-2">
				<label>Notes</label>
				<textarea
					rows={6}
					readOnly={true}
					className="form-control"
					value={licence.notes ? licence.notes : null}
				></textarea>
			</div>


			<button
				onClick={() => {
					props.history.goBack();
				}}
				className="btn btn-outline-primary mt-3 mb-5"
			>
				GO BACK
			</button>
		</div>
	);
};


export default branch(
	{
		licences: ['licences'],
	},
	LicenceView
);