import React from 'react'
import { rest } from '../../data/rest/api';
import  { closeModal, showModal } from '../../layout/modal';
import BillingFeatureForm from './billingFeatureForm';
import { toast } from 'react-toastify';

const FormModal = ({ billingFeatureItem, saveItem }) => {
    return (
        <div>
            <h3>Instance: {billingFeatureItem.instanceName}</h3>
            <BillingFeatureForm saveItem={saveItem} billingFeatureItem={billingFeatureItem} closeModal={closeModal} />
        </div>
    );
};

export default function BillingTable() {
    const [instancesFeatures, setInstancesFeatures] = React.useState({})
    const [defaultBillingListItems, setDefaultListItems] = React.useState([])

    React.useEffect(() => {
        const loadData = async () => {
            const instancesFeatueList = await rest('GET', '/getAllInstancesFeatureListItems');
            const { featureListItems, defaultListItems } = instancesFeatueList

            const groupedData = featureListItems.reduce((result, obj) => {
                const instanceName = obj.instanceName;
                if (!result[instanceName]) {
                    result[instanceName] = [];
                }
                result[instanceName].push(obj);
                return result;
            }, {});


            setInstancesFeatures(groupedData);
            setDefaultListItems(defaultListItems);

        };

        loadData().catch((err) => {
            console.log(err);
        });

    }, [])

    const saveItem = async (featureItem) => {
        const instanceName = featureItem && featureItem.instanceName ? featureItem.instanceName : null
        if (instanceName && instanceName.length > 0) {
            try {
                const itemToSave = { ...featureItem }
                if (featureItem.enableDiscountPercentage === false) {
                    itemToSave["discountPercentage"] = null
                }
                delete itemToSave.enableDiscountPercentage

                await rest("POST", "/createOrUpdateBillingFeatureListItem", { billingFeatureListItem: itemToSave, instanceName: instanceName })
                toast.info("Successfully updated")
                window.location.reload()

            } catch (error) {
                toast.error(error.message)
            }

        } else {
            toast.error("Instance not selected")
        }
    }

    function getTableData() {
        const billingFeatureNames = defaultBillingListItems.map(itm => { return itm.name })
        const row = []

        for (const instanceName in instancesFeatures) {
            row.push(
                <div key={instanceName} style={{ display: "flex", borderBottom: "1px solid lightgray", borderLeft: "1px solid lightgray" }}>
                    <div style={{ flex: 1, paddingLeft: "4px" }}>{instanceName}</div>
                    {billingFeatureNames.map((feature, ind) => {
                        const existingFeature = instancesFeatures[instanceName].find(itm => itm.name === feature)
                        if (existingFeature && existingFeature.active ) {
                            return <div key={ind} onClick={async () => {
                                showModal(
                                    `${existingFeature.name}`,
                                    <FormModal billingFeatureItem={existingFeature} saveItem={saveItem} />,
                                    false,
                                    "modal-xl"
                                );
                            }} style={{  cursor:"pointer",padding: "6px 4px", flex: 1, borderLeft: "1px solid lightgray", borderRight: ind === defaultBillingListItems.length - 1 ? "1px solid lightgray" : "none" }}><span className='text-success'>ACTIVE</span></div>
                        } else {
                            const defaultItem = defaultBillingListItems.find(itm => itm.name === feature)

                            return <div key={ind} onClick={async () => {

                                showModal(
                                    `${defaultItem.name}`,
                                    <FormModal billingFeatureItem={{ ...defaultItem, instanceName: instanceName }} saveItem={saveItem} />,
                                    false,
                                    "modal-xl"
                                );
                            }}
                                style={{ cursor:"pointer", padding: "6px 4px", flex: 1, borderLeft: "1px solid lightgray", borderRight: ind === defaultBillingListItems.length - 1 ? "1px solid lightgray" : "none" }}><span className='text-danger'>INACTIVE</span></div>
                        }
                    })}
                </div>
            )
        }
        return row
    }

    return (
        <div>
            <div style={{ display: "flex", borderBottom: "1px solid lightgray", borderTop: "1px solid lightgray" }}>
                <div style={{ flex: 1, borderLeft: "1px solid lightgray", paddingLeft: "4px" }}><strong>Instance Name</strong></div>
                {defaultBillingListItems.map((itm, ind) => { return <div key={ind} style={{ padding: "6px 4px", flex: 1, borderLeft: "1px solid lightgray", borderRight: ind === defaultBillingListItems.length - 1 ? "1px solid lightgray" : "none", paddingLeft: "4px" }}><strong>{itm.name}</strong></div> })}
            </div>
            {getTableData()}
        </div>
    )
}
