import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { fetchInstanceFeatureFlags, toggleFeature } from './api';
import { startGlobalLoading, stopGlobalLoading } from '../../layout/globalLoading';

import { FiSquare,FiCheckSquare } from 'react-icons/fi';


const FeatureFlagsToggler = (props) => {
	const [featureFlags, setFeatureFlags] = React.useState([]);
	const { uuid = null } = props.match?.params ? props.match.params : null;

	const instance = props.instances.find((i) => i.uuid === uuid);

	React.useEffect(() => {
		const loadData = async () => {
			startGlobalLoading();

			let fflags = []  
			if(instance){
				fflags = await fetchInstanceFeatureFlags(instance.name);			

			}
			setFeatureFlags(fflags);
			stopGlobalLoading();
		};

		loadData();
	}, [instance]);

	async function toggleFeatureFlag(id){
		try {
			await toggleFeature(id, instance.name)
			
			let nekiNoviNiz = featureFlags.map((val, key) => {
				if(val.id === id){
					val.isEnabled = val.isEnabled === 0 ? 1 : 0
				}
				return val
			})
			setFeatureFlags(nekiNoviNiz)
		} catch (error) {
			console.log('error');
		}
	}

	if (instance === null || !instance) {
		return 'Loading ...';
	}

	return (
		<div>
			<button
				onClick={() => {
					props.history.goBack();
				}}
				className="btn btn-outline-primary mt-3"
			>
				GO BACK
			</button>

			<hr className=" mb-5"/>

			<ul className="list-group m-3">
                {featureFlags ? featureFlags.map((f, key) => {
                    const checkboxIcon = f.isEnabled === 1 ? (
                        <FiCheckSquare/>
                    ) : (
						<FiSquare />
                        );

                    return (
                        <li                         
                            className={
                                'px-1 py-2 row shadow-sm pointer ' +
                                (key % 2 === 0 ? 'bg-white ' : '')
                            }
                            key={key}
                        >
                            <div className="col-4">{f.name}</div>
                            <div className="col-3">{f.description}</div>
                            <div className="col-2">{f.version}</div>
                            <div className="col-1" ><span style={{cursor:"pointer"}} onClick={()=>{toggleFeatureFlag(f.id)}}>{checkboxIcon}</span></div>
                        </li>
                    );
                }) : null}
            </ul>
		</div>
	);
};

export default branch(
	{
		instances: ['instances'],
	},
	FeatureFlagsToggler
);