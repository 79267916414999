import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import baobab from '../data/state';
import "./globalLoading.css"

export function GlobalLoading() {
    const { loading } = useBranch({
        loading: ['globalLoading'],
    });

    if (loading > 0) {
        return (
            <div className="loader-cover-spin">
            </div>
        );
    } else {
        return null;
    }
}

export function startGlobalLoading() {
    baobab.root.select('globalLoading').apply((prev: number) => prev + 1);
}

export function stopGlobalLoading() {
    baobab.root.select('globalLoading').apply((prev: number) => prev - 1);
}
