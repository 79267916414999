import baobab from '../state';
import signInActions from "../signInActions"

const getRequestHeaders = () => {
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Accept', 'application/json');
    const token = baobab.root.select('keycloak', 'token').get();
    if (token) {
        requestHeaders.set('Authorization', 'Bearer ' + token);
    }
    return requestHeaders;
};


//result is a promise
// GET, POST, PUT, DELETE
async function rest(method = "GET", url, payload){
    const response = await fetch(url, {
        method: method,
        headers: getRequestHeaders(),
        body: payload ? JSON.stringify(payload) : undefined,
    });

    const status = response.status;
    if(status===401 || status===402 || status===403){
        signInActions.logout();
    }
    if (status !== 200) {
        const errObject = await response.json();
        const errString =
            errObject && errObject.data && errObject.data.message ? errObject.data.message : 'Something went wrong.';
        throw new Error(errString);
    }
    const json = await response.json();
    return json.data ? json.data : json;
}


export { getRequestHeaders, rest };