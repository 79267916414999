import React, { useEffect, useState } from 'react'
import { rest } from '../../data/rest/api';

export default function SingleInvoice(props) {
    const [invoiceHeader, setInvoices] = useState([])
    const [invoiceItems, setInvoiceItems] = useState([])
    const [jsonShown, setjsonShown] = useState(false)

    useEffect(() => {
        const loadData = async () => {

            const inv = await rest('POST', '/getInstanceInvoice/' + props.match.params.invoiceId, { instanceName: props.match.params.instanceName });
            const invItems = await rest('POST', '/getInstanceInvoiceItems/' + props.match.params.invoiceId, { instanceName: props.match.params.instanceName });

            setInvoices(inv)
            setInvoiceItems(invItems)
            // setInvoices(inv);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [])

    return (
        <div className='printable-list' style={{ padding: "8px" }}>
            <div style={{ display: "flex", justifyContent: "space-around", margin: "4px 0", borderBottom: "1px solid lightgray" }}>

                <span style={{ flex: 3 }}><strong>Item Name</strong></span>
                <span style={{ flex: 1 }}><strong>Quantity</strong></span>
                <span style={{ flex: 1 }}><strong>Unit price</strong></span>
                <span style={{ flex: 1 }}><strong>Discount percentage</strong></span>
                <span style={{ flex: 1 }}><strong>Discount value</strong></span>
                <span style={{ flex: 1 }}><strong>Tax Percentage </strong></span>
                <span style={{ flex: 1 }}><strong>Tax Value With Discount</strong></span>
                <span style={{ flex: 1 }}><strong>Value With Discount</strong></span>

            </div>
            {invoiceItems.map((invoiceItem, ind) => {
                return (
                    <div key={ind} style={{ display: "flex", justifyContent: "space-around", margin: "4px 0", borderBottom: "1px solid lightgray" }}>
                        <span style={{ flex: 3 }}>{invoiceItem.itemName}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.quantity}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.unitPrice}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.discountPercentage}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.discountValue}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.taxPercentage1}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.tax1ValueWithDiscount}</span>
                        <span style={{ flex: 1 }}>{invoiceItem.valueWithDiscount}</span>

                    </div>)
            })}



            <div style={{ position: "relative", background: "#e7e7e7", flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row" }}
            >
                <span style={{ flex: 1 }}><strong>Month: </strong>{invoiceHeader.month}</span>
                <span style={{ flex: 1 }}><strong>Tax rate: </strong>{invoiceHeader.totalBaseVAT_BD}</span>
                <span style={{ flex: 1 }}><strong>Tax: </strong>{invoiceHeader.totalVAT}</span>
                <span style={{ flex: 1 }}><strong>Total value: </strong>{Number(invoiceHeader.totalValue).toFixed(4)}</span>

            </div>
            {/* <div >
              <div
                  style={{
                      position: "relative", background: "#e7e7e7",
                      flex: 1, margin: "4px", padding: "8px 12px", borderRadius: "8px", display: "flex", flexDirection: "row"
                  }}
              >
                  <span style={{ flex: 1 }}>{invoiceHeader.month}</span>
                  <span style={{ flex: 1 }}>{invoiceHeader.totalBaseVAT_BD}</span>
                  <span style={{ flex: 1 }}>{invoiceHeader.totalVAT}</span>
                  <span style={{ flex: 1 }}></span>
              </div>
          </div> */}
          <div onClick={()=>{setjsonShown(!jsonShown)}} className='btn btn-info'>{jsonShown ? "hide" : "show"} json</div>
          {!jsonShown ? null : <div>
            <textarea style={{width:"100%"}} rows={30} columns={100}  value={JSON.stringify(invoiceItems)}>
          </textarea>
          <textarea style={{width:"100%"}} rows={5} columns={100}  value={JSON.stringify(invoiceHeader)}>
          </textarea>
            </div> }
        </div>
    )
}
