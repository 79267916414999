import baobab from './state';
import {rest} from "./rest/api"

const init = async ()=>{


	const instances = await rest("GET", "/api/instance")
	baobab.root.select('instances').set(instances);

	const licences = await rest("GET", "/api/licence")
	baobab.root.select('licences').set(licences);

	console.log("startup init() completed");

}


export default init