import Baobab from 'baobab';

var initialStateObject = {
    instances:[],
    licences:[],
    globalLoading:0,
    keycloak:{},
    modalData:{
        width:"300px",
        display: false,
        showBottomCloseButton: true
    }
};
var tree = new Baobab(initialStateObject, { immutable: true });
window.baobab = tree


export default tree;