import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { useBranch } from 'baobab-react/hooks';
// import { deleteInstance } from './api';
import { closeModal, showModal } from '../../layout/modal';
import { rest } from '../../data/rest/api';
import BillingFeatureForm from './billingFeatureForm';
import { toast } from 'react-toastify';

const FormModal = ({ billingFeatureItem, saveItem }) => {
return (
		<div>
			<h3>FORM</h3>
			<BillingFeatureForm saveItem={saveItem} billingFeatureItem={billingFeatureItem} closeModal={closeModal}/>
            
{/* 
			<button
				onClick={() => {
					closeModal();
				}}
				className="btn btn-secondary"
			>
				CANCEL
			</button>
			<button
				style={{ marginLeft: '10px' }}
				onClick={() => {
					// deleteInstance(deleteCandidate);
					closeModal();
				}}
				className="btn btn-danger"
			>
				CONFIRM
			</button> */}
		</div>
	);
};

const FeatureItems = (props) => {
	const { instances } = useBranch({
		instances: ['instances'],
	});

    const [billingFeatureListItems, setBillingFeatureListItems] = React.useState([])

    React.useEffect(() => {
        const loadData = async () => {
            const instanceBillingItems = await rest('POST', '/getFeatureListItems', { instanceName:props.match.params.instanceName });
            setBillingFeatureListItems(instanceBillingItems);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);


    const saveItem = async(featureItem) => {
        try {
            const itemToSave = {...featureItem}
            if (featureItem.applyDiscountPercentage === false) {
                itemToSave["discountPercentage"] = null
            }
            delete itemToSave.applyDiscountPercentage
            await rest("POST","/createOrUpdateBillingFeatureListItem", {billingFeatureListItem:itemToSave, instanceName:props.match.params.instanceName})     
            toast.info("Successfully updated")
            window.location.reload()

        } catch (error) {
            toast.error(error.message)
        }
    

    }
    

	return (
		<div className="container">
            <div >
                <h1>{props.match.params.instanceName}</h1>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "4px", paddingBottom:"12px" }}>
                    <strong style={{ flex: 1 }}>Feature name</strong>
                    <strong style={{ flex: 1 }}>Feature set for instance</strong>
                    <strong style={{ flex: 1 }}>Visibility</strong>
                    <strong style={{ flex: 1 }}>Activation Status</strong>
                    <strong style={{ flex: 1, textAlign: "right" }}>
                        Edit
                    </strong>
                </div>
               

            {billingFeatureListItems.map((item, ind) => {
                return <div key={ind} style={{display:"flex", justifyContent:"space-between", marginTop:"4px"}}> <span style={{flex:1}}>{item.name}</span> 

                <span style={{flex:1}}>{item.instanceName ? "Set" : "Not set"}</span>
                <span style={{flex:1}}>{item.enabled ? <span className='text-success'>Visible to user</span> : <span className='text-danger'>Hidden to user</span>}</span>
                <span style={{flex:1}}>{item.active ? <span className='text-success'>Activated</span> : <span className='text-danger'>Deactivated</span>}</span>
                <span style={{flex:1, textAlign:"right"}}>
                <button
                onClick={() => {
                    showModal(
                        `${item.name}`,
                        <FormModal billingFeatureItem={item} saveItem={saveItem}/>,
                        false,
                        "modal-xl"
                    );
                }}
                className={'btn btn-warning'}
            >
                <FiEdit />
            </button>
                </span>
            <div>
            {/* <BillingFeatureForm saveItem={saveItem} billingFeatureItem={item} closeModal={closeModal}/> */}
                </div>
            </div>
            })}
            </div>
		</div>
	);
};

export default FeatureItems;