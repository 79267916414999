import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import baobab from '../data/state';
import './modal.css';

var content = null;
var title = null;
var alternateWidthClassname = ""

function MainModal(props) {
    const { data } = props;
    const { width, showBottomCloseButton, display } = data;

    let style = display === true ? { display: 'block', overflowY: 'auto' } : { display: 'none' };

    return (
        <div id="modal-overlay" style={style}>
            <div className="modal fade show" id="mainModal" tabIndex="-1" style={style}>
                <div className={'modal-dialog ' + width + " " +alternateWidthClassname}>
                    <div className="modal-content">
                        {title ? (
                            <div className="modal-header">
                                <h5 className="modal-title">{title}</h5>
                                <button type="button" className="btn btn-sm btn-default" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div className="modal-body">{content}</div>

                        {showBottomCloseButton ? (
                            <div className="modal-footer">
                                <button onClick={closeModal} type="button" className="btn btn-secondary">
                                    Close
                                </button>{' '}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

const closeModal = () => {
    baobab.root.select(['modalData', 'display']).set(false);
};

const showModal = (_title, _content, showBottomCloseButton = true, alternateWidthClass="") => {
    content = _content;
    title = _title;
    alternateWidthClassname = alternateWidthClass
    baobab.root.select(['modalData', 'display']).set(true);
    baobab.root.select(['modalData', 'showBottomCloseButton']).set(showBottomCloseButton);
};

export { closeModal, showModal };

export default branch(
    {
        data: ['modalData'],
    },
    MainModal
);