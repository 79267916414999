import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import MasterLayout from "./layout/masterLayout";
import InstanceManager from "./pages/instances/manager";
import Dashboard from "./pages/dashboard/dashboard";
import InstanceForm from "./pages/instances/form";
import InstanceFeatureFlags from "./pages/instances/featureFlags"
import LicenceGenerator from "./pages/licenceGenerator"
import LicenceForm from "./pages/licenceGenerator/licenceForm"
import LicenceView from "./pages/licenceGenerator/licenceView"

import Billing from "./pages/billing";
import BillingFeatureTable from "./pages/billing/billingTable";



import BillingFeatureItems from "./pages/billing/featureItems";
import InvoiceList from "./pages/billing/invoices";
import Invoice from "./pages/billing/singleInvoice";
import GeneralSettings from "./pages/generalSettings";

import SignInActions from "./data/signInActions";
import startupInit from "./data/startupActions"

const PageNotFound = () => {
	return <div>404 - PAGE NOT FOUND</div>;
};

const AppRouter = (props) => {
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		SignInActions.initKeycloak().then(() => {
			setLoading(false);
			startupInit();
		});
	}, []);

	if (loading) {
		return "Loading...";
	}

	return (
		<Router {...props}>
			<MasterLayout {...props}>
				<Switch {...props}>
					<Route exact path="/" component={Dashboard} />

					<Route
						exact
						path="/instances"
						component={InstanceManager}
					/>

					<Route
						exact
						path="/licences"
						component={LicenceGenerator}
					/>

					<Route
						exact
						path="/licences/view/:uuid"
						component={LicenceView}
					/>

					<Route
						exact
						path="/licences/edit/:uuid"
						component={LicenceForm}
					/>

					<Route
						exact
						path="/licences/new"
						component={LicenceForm}
					/>


					<Route
						exact
						path="/instances/new"
						component={InstanceForm}
					/>

					<Route
						exact
						path="/instances/edit/:uuid"
						component={InstanceForm}
					/>


					<Route
						exact
						path="/instances/fflags/:uuid"
						component={InstanceFeatureFlags}
					/>

					<Route
						exact
						path="/billing"
						component={Billing}
					/>
					
					<Route
						exact
						path="/billingFeatresTable"
						component={BillingFeatureTable}
					/>
					<Route
						exact
						path="/billingFeatureItems/:instanceName"
						component={BillingFeatureItems}
					/>

					<Route
						exact
						path="/invoices/:instanceName"
						component={InvoiceList}
					/>

					<Route
						exact
						path="/invoices/:instanceName/:invoiceId"
						component={Invoice}
					/>

<Route
						exact
						path="/generalSettings"
						component={GeneralSettings}
					/>
					<Route component={PageNotFound} />
				</Switch>
			</MasterLayout>
		</Router>
	);
};

export default AppRouter;