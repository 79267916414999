import React from 'react';
import moment from 'moment';
import { useBranch } from 'baobab-react/hooks';
import { FiEdit, FiInfo /*FiTrash2, FiList,*/ } from 'react-icons/fi';

const LicenceManagement = (props) => {
	const { licences } = useBranch({
		licences: ['licences'],
	});

	return (
		<div className="container">
			<div className="jumbotron jumbotron-fluid">
				<div className="">
					<h1 className="display-4">Licence management</h1>
					<p className="lead">Manage on premise licences here</p>
				</div>

				<button
					onClick={() => {
						props.history.push('/licences/new');
					}}
					type="button "
					className="btn btn-primary btn-lg"
				>
					CREATE LICENCE
				</button>
			</div>

			<table className="table">
				<thead>
					<tr>
						<th scope="col">Company / Support</th>
						<th scope="col">Package</th>
						<th scope="col">Activation date / Refresh date</th>

						<th scope="col">Trial date</th>
						<th scope="col">Expiration date</th>
						<th scope="col">Manage</th>
					</tr>
				</thead>
				<tbody>
					{licences.map((lic) => {
						return (
							<tr key={lic.uuid}>
								<th scope="row">
									{lic.company}
									<br />
									<small>{lic.administrativeEmail}</small>
								</th>
								<td>{lic.package}</td>
								<td className="text-center">
									<small>
										{moment(lic.activationDate).format('lll')}
										<br />
										{moment(lic.lastLicenceRefresh).format('lll')}
									</small>
								</td>
								<td className="text-center">
									<small>{moment(lic.trialToDate).format('lll')}</small>
								</td>
								<td className="text-center">
									<small>{moment(lic.expirationDate).format('lll')}</small>
								</td>

								<td>
									<button
										onClick={() => {
											props.history.push(`/licences/edit/${lic.uuid}`);
										}}
										className="btn btn-outline-primary"
									>
										<FiEdit />
									</button>
									&ensp;
									<button
										onClick={() => {
											props.history.push(`/licences/view/${lic.uuid}`);
										}}
										className="btn btn-outline-info"
									>
										<FiInfo />
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default LicenceManagement;