import React from 'react';
import { FiEdit, FiTrash2, FiList, FiInfo } from 'react-icons/fi';
import { useBranch } from 'baobab-react/hooks';
import { deleteInstance } from './api';
import { closeModal, showModal } from '../../layout/modal';

const DeleteModal = ({ deleteCandidate }) => {
	return (
		<div>
			<h3>Are you sure you want to delete {deleteCandidate.name}?</h3>
			<p>
				<b>This operation is not reversibile!</b> After confirmation, all related data and databases would also
				be permanentyl removed.
			</p>

			<button
				onClick={() => {
					closeModal();
				}}
				className="btn btn-secondary"
			>
				CANCEL
			</button>
			<button
				style={{ marginLeft: '10px' }}
				onClick={() => {
					deleteInstance(deleteCandidate);
					closeModal();
				}}
				className="btn btn-danger"
			>
				CONFIRM
			</button>
		</div>
	);
};

const InstanceManager = (props) => {
	const { instances } = useBranch({
		instances: ['instances'],
	});

	return (
		<div className="container">
			<div className="jumbotron jumbotron-fluid">
				<div className="">
					<h1 className="display-4">Instances directory</h1>
					<p className="lead">All created instances are here, regardless of their status.</p>
					<button
						onClick={() => {
							props.history.push('/instances/new');
						}}
						type="button "
						className="btn btn-primary btn-lg"
					>
						CREATE INSTANCE
					</button>
				</div>
			</div>

			<table className="table">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Version</th>
						<th scope="col">Status</th>
						<th scope="col">Company</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					{instances.map((t) => {
						return (
							<tr key={t.uuid}>
								<th scope="row">{t.name}</th>
								<td>{t.version}</td>
								<td>{t.status}</td>
								<td>{t.company}</td>
								<td>
									<button
										onClick={() => {
											props.history.push(`/instances/fflags/${t.uuid}`);
										}}
										className="btn btn-outline-warning"
									>
										<FiList />{' '}
									</button>
									&ensp;
									<button
										onClick={() => {
											props.history.push(`/instances/edit/${t.uuid}`);
										}}
										className="btn btn-outline-primary"
									>
										<FiEdit />{' '}
									</button>
									&ensp;
									<button
										onClick={() => {
											showModal(
												'Please take a precaution ',
												<DeleteModal deleteCandidate={t} />,
												false
											);
										}}
										className={'btn btn-danger'}
									>
										<FiTrash2 />
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default InstanceManager;