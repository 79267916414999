import Keycloak from 'keycloak-js';
import baobab from './state';

const keycloak = Keycloak('/keycloakConfig'); //pull config from backend

const SignInActions = {
	initKeycloak: () => {
		return keycloak
			.init({ onLoad: 'login-required', checkLoginIframe: false })
			.then((authenticated) => {
				const { realm, realmAccess, resourceAccess, sessionId, token, tokenParsed, refreshToken } = keycloak;

				//console.log(keycloak.token);

				if (authenticated) {
					baobab.root.select('keycloak').set({
						realm,
						realmAccess,
						resourceAccess,
						sessionId,
						token,
						tokenParsed,
						refreshToken,
						authenticated,
					});
				} else {
					console.log('nothing!?');
				}

				keycloak.onTokenExpired = () => {
					keycloak
						.updateToken(30)
						.then((authenticated) => {
							if (authenticated) {
								baobab.root.select('keycloak').set('token', keycloak.token);
							} else {
								//console.log('LOGOUT ovdje');
								baobab.root.select('keycloak').set({});
								keycloak.logout();
							}
						})
						.catch((err) => {
							console.log('LOGOUT ovdje jer je failao u dohvaćanju tokena');
						});
				};
			})
			.catch((err) => {
				console.warn(err);
			});
	},
	logout: async ()=> {
		keycloak.logout();
		baobab.root.select('keycloak').set({});
		
	},
};

export default SignInActions;