

import React, { useState, useEffect } from 'react'
import { rest } from '../../data/rest/api';
import { toast } from 'react-toastify';

export default function GeneralSettings() {
    const [allSettings, setallSettings] = useState({})
    const [touchedSettings, setTouchedSettings] = useState([])

    const settingsTemplate = {
        defaultCurrency: '',
        defaultVAT: ''
    }

    React.useEffect(() => {
        const loadSettings = async () => {
            let allSettings = {}
            for (const key in settingsTemplate) {
                const data = await rest("GET", '/getGeneralSettingByKey/' + key);
                // allSettings.push(data)
                allSettings[key] = data.params

            }
            setallSettings(allSettings)
        };

        loadSettings().catch((err) => {
            toast.error(err.message)
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const onValueChange = (key, e) => {
        let touchedSettingsState = [...touchedSettings]
        if(!touchedSettings.includes("key")){
            touchedSettingsState.push(key)
        }
        let allSettingsState = { ...allSettings }
        allSettingsState[key] = e.target.value
        setallSettings(allSettingsState)
        setTouchedSettings(touchedSettingsState)
    }

    const saveFormData = async (key) => {
        let obj = {};

        obj['key'] = key;
        obj['params'] = allSettings[key];
        try {
            await rest("POST",'/saveGeneralSetting', obj);
            toast.info("Successfully updated")
        // let touchedSettingsState = [...touchedSettings]
        let touchedSettingsState = touchedSettings.filter(set =>  set !== key)
        
        setTouchedSettings(touchedSettingsState)

        } catch (error) {
            console.warn('err', error);
        }
    };
    return (
        <div>
            <h3>General settings</h3>
            <div style={{width:"80%", paddingTop:"12px"}}>
            {Object.keys(allSettings).map((key) => (
                <div style={{display:"flex", justifyContent:"space-between", marginTop:"8px"}} key={key}>
                    <span style={{flex:1, textAlign:"center"}}>{key}: </span>
                    <input style={{flex:1}} onChange={(e) => { onValueChange(key, e) }} name={key} value={allSettings[key]}></input>
                <div  style={{flex:1, textAlign:"left", paddingLeft:"30px"}} >
                {touchedSettings.includes(key) ? <div className='btn btn-info' onClick={() => { saveFormData(key) }}>Save</div> : null}

                </div>
                </div>
            ))}

            {/* <div className='btn btn-info'>Save</div> */}
        </div></div>
    )
}
