import baobab from '../../data/state';
import { rest } from '../../data/rest/api';
import { startGlobalLoading, stopGlobalLoading } from '../../layout/globalLoading';
import { toast } from 'react-toastify';

function getModelTemplate() {
	const modelTemplate = {
		uuid: null,
		name: '',
		version: '',
		adminEmail: '',
		adminName: '',
		adminPassword: '',
		company: '',
		status: 'running',
		requestsMemory: '0Mi',
		requestsCPU: '0m',
		limitsCPU: '1000m',
		limitsMemory: '1024Mi',
		priority: 'low-priority',
		minReplicas: 1,
		maxReplicas: 1,
	};
	return modelTemplate;
}

async function createInstance(instance) {
	try {
		startGlobalLoading();
		//first validate a name
		await rest('POST', '/api/validateInstanceName', {instanceName: instance.name});
		const createdInstance = await rest('POST', '/api/instance', instance);
		baobab.root.select('instances').push(createdInstance);
		stopGlobalLoading();
		return createdInstance;
	} catch (err) {
		stopGlobalLoading();
		toast.error(err.message);
	}
}

async function updateInstance(instance) {
	try {
		startGlobalLoading();
		const { uuid } = instance;
		baobab.root.select('instances', { uuid }).set(instance);
		const updatedData = await rest('PUT', '/api/instance/' + uuid, instance);
		baobab.root.select('instances', { uuid }).set(updatedData);
		stopGlobalLoading();
		return updatedData;
	} catch (err) {
		stopGlobalLoading();
		toast.error(err.message);
	}
}

//database name is alway the same as the isntance name!
async function fetchInstanceFeatureFlags(instanceName) {
	try {
		const featureFlags = await rest('GET', '/api/fetchInstanceFeatureFlags/' + instanceName);
		return featureFlags;
	} catch (err) {
		toast.error(err.message);
	}
}

//database name is alway the same as the isntance name!
async function toggleFeature(ffId, instanceName) {
	try {
		let resp = await rest('GET', '/api/toggleFeatureFlag/' + instanceName + '/' + ffId);
		return resp;
	} catch (err) {
		toast.error(err.message);
	}
}

async function deleteInstance(instance) {
	try {
		let resp = await rest('DELETE', '/api/instance/' + instance.uuid);
		baobab.root.select('instances', {uuid: instance.uuid}).unset();

		return resp;
	} catch (err) {
		toast.error(err.message);
	}
}

export { getModelTemplate, createInstance, updateInstance, deleteInstance };
export { fetchInstanceFeatureFlags, toggleFeature };