import React from 'react';
import { rest } from '../../data/rest/api';
import { FiCodesandbox } from 'react-icons/fi';
import { startGlobalLoading, stopGlobalLoading } from '../../layout/globalLoading';
import moment from 'moment';

const DashboardTile = (props) => {
	return (
		<div
			style={{ justifyContent: 'flex-start', flexFlow: 'wrap', width: '200px' }}
			className="border bg-white rounded shadow-sm p-3 m-2"
		>
			{props.children}
		</div>
	);
};

const GcManagerUpdates = ({ gcImagesInfo, updateInstanceManager }) => {
	if (gcImagesInfo === null) {
		return null;
	}

	if (gcImagesInfo.list.length === 0) {
		return null;
	}

	return (
		<div>
			<b>Current version: {gcImagesInfo ? gcImagesInfo.deployedVersion : 'unknown'}</b>

			<ul className="list-group ">
				{gcImagesInfo.list.map((i, index) => {
					console.log(gcImagesInfo.deployedVersion, i.tags[0]);
					const activeVersionCss =
						gcImagesInfo.deployedVersion === i.tags[0]
							? 'list-group-item d-flex justify-content-between text-danger'
							: 'list-group-item d-flex justify-content-between';

					return (
						<li key={index} className={activeVersionCss}>
							<b>{i.tags[0]}</b> <div>{moment(i.date).format('ll')}</div>{' '}
							<div>{moment(i.date).fromNow()}</div>{' '}
							<div>
								<button
									onClick={() => {
										updateInstanceManager(i.tags[0]);
									}}
									className="btn btn-sm btn-primary"
								>
									DEPLOY
								</button>{' '}
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const Dashboard = (props) => {
	const [cmdOutput, setCmdOutput] = React.useState('');
	const [gcImagesInfo, setGcImagesInfo] = React.useState(null);

	async function fetchGcManagerUpdates() {
		startGlobalLoading();
		setCmdOutput('');
		const _gcImagesInfo = await rest('post', '/api/getManagerContainersList', {});
		setGcImagesInfo(_gcImagesInfo);
		stopGlobalLoading();
	}

	async function fetchCMDOutput(cmd = 'getPods') {
		startGlobalLoading();
		setGcImagesInfo(null);
		const podInfo = await rest('POST', '/api/executeCommand', { cmd });
		stopGlobalLoading();
		setCmdOutput(podInfo);
	}

	async function updateInstanceManager(gcManagerTargetVersion) {
		startGlobalLoading();
		const result = await rest('POST', '/api/updateInstanceManager', { gcManagerTargetVersion });
		stopGlobalLoading();
	}

	return (
		<div>
			<div className="jumbotron">
				<FiCodesandbox style={{ height: 128, width: 128 }} />
				<h1 className="display-4">KUBE dashboard</h1>
				<p className="lead">Deploy and manage kubernetes pods on cloud infrastructure.</p>
				<button
					onClick={() => {
						props.history.push('/instances/new');
					}}
					type="button "
					className="btn btn-primary btn-lg"
				>
					CREATE INSTANCE
				</button>
				&ensp;
				<button
					onClick={() => {
						fetchCMDOutput('getPods');
					}}
					type="button "
					className="btn btn-outline-primary btn-lg"
				>
					SHOW PODS INFO
				</button>
				&ensp;
				<button
					onClick={() => {
						fetchCMDOutput('showDeployment');
					}}
					type="button "
					className="btn btn-outline-primary btn-lg"
				>
					SHOW deployment.yaml
				</button>
				&ensp;
				<button
					onClick={() => {
						fetchGcManagerUpdates();
					}}
					type="button "
					className="btn btn-outline-primary btn-lg"
				>
					SHOW UPDATES
				</button>
			</div>

			<hr className="my-4" />

			<GcManagerUpdates updateInstanceManager={updateInstanceManager} gcImagesInfo={gcImagesInfo} />

			{cmdOutput ? (
				<textarea readOnly={true} style={{ width: '100%', height: '50vh' }} value={cmdOutput}></textarea>
			) : null}

			<div className="mt-3" style={{ display: 'flex' }}>
				{/*<DashboardTile>Running pods: TBD</DashboardTile>
				<DashboardTile>Failures: TBD</DashboardTile>
				<DashboardTile>Status: OPERATIONAL</DashboardTile>
			*/}
			</div>
		</div>
	);
};

export default Dashboard;